exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-albums-js": () => import("./../../../src/pages/albums.js" /* webpackChunkName: "component---src-pages-albums-js" */),
  "component---src-pages-bio-page-js": () => import("./../../../src/pages/bioPage.js" /* webpackChunkName: "component---src-pages-bio-page-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-gigs-js": () => import("./../../../src/pages/gigs.js" /* webpackChunkName: "component---src-pages-gigs-js" */),
  "component---src-pages-hire-chris-js": () => import("./../../../src/pages/hireChris.js" /* webpackChunkName: "component---src-pages-hire-chris-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacyPolicy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-pages-videos-js": () => import("./../../../src/pages/videos.js" /* webpackChunkName: "component---src-pages-videos-js" */),
  "component---src-templates-album-template-album-template-js": () => import("./../../../src/templates/albumTemplate/albumTemplate.js" /* webpackChunkName: "component---src-templates-album-template-album-template-js" */)
}

